.containsForm {
    width: 100%;
    padding: 1.4rem;
    border: 2px solid black;
    background-color: rgb(219, 194, 244);
    border-radius: 10px;
    font-size: 1.5rem;
    /* font-family: Cambria; */
}

.submitBtn[type="submit"] {
    /* background-color: #efc1ee; */
    /* color: #070707; */
    /* border: none;
    padding: 10px 20px; */
    border-radius: 1rem;
    /* cursor: pointer; */
    display: block;
    margin: 0 auto;
    width: 100px;
}

