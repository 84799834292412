.body {
    background-color: #ddd;
}

.card {
    /* border: none; */
    background-color: rgb(219, 194, 244);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    border: 1px solid black;

}

.card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-img-top {
    height: 200px;
    object-fit: cover;
    transition: 0.4s;
}

.card-img-top:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}

.card-body {
    padding: 1rem;
    text-align: center;
}

.card-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 21px;
}

.card-text {
    font-size: 14px;
}

.card-price {
    font-size: 18px;
    font-weight: bold;
    color: rgb(102, 16, 215);
    margin-top: 0.5rem;
}

.row-cols-1.row-cols-sm-2.row-cols-md-3.row-cols-lg-4 {
    margin: 0;
}

.mb-3 {
    margin-bottom: 1rem;
}

@media (min-width: 576px) {
    .my-3 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}


.btn-class {
    background-color: blueviolet;
    border: 2px solid black;
}

.btn-class:hover {
    background-color: rebeccapurple;
    border: 2px solid black;

}