.Navbar {
  /* padding-bottom: 100px; */
  display: flex;
  justify-content: left;
  padding: 20px;
  position: fixed;
  width: 90%;
  height: 4rem;
  z-index: 99;
  border-radius: 2rem;
  border: 3px solid rebeccapurple;
  border-right: none;
  margin-top: 10px;
  margin-left: 4rem;
  background-color: rgb(23, 23, 23);
}

nav {

  margin: 0;
  padding: 0;
}

.NavLink {
  display: inline-block ;
  list-style: none;

  /* padding: 0 10px; */
  margin: 0 20px;
  cursor: pointer;
  /* border: 2px solid rebeccapurple; */
  position: relative;

}

.NavLink:after {
  content: '';
  width: 0;
  height: 3px;
  background: rgb(135, 72, 199);
  position: absolute;
  left: 0;
  bottom: -10px;
  transition: 1s;
}

.NavLink:hover::after {
  width: 100%;
}

.text {
  color: rgb(162, 86, 239);
  font-size: large;
  font-weight: 550;
}