.Hero {
    /* background-image: url('/public/Images/GoGreen.png'); */
    /* background-color: rgb(159, 79, 240); */
    background-image: linear-gradient(to bottom right, rgb(182, 117, 247), rgb(94, 34, 155));

    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    background-position: center;
    min-height: 70vh;
    justify-content: center;
    padding-top: 1rem;
}

.home-about {
    background-image: linear-gradient(to bottom right, rgb(182, 117, 247), rgb(94, 34, 155));
}